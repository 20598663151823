
              @import "@/assets/css/variables.scss";
            

























































.fund_card {
  cursor: pointer;
  border-radius: 8px;
  background-color: $background-color-base;
  border: 1px solid $background-color-base;
  padding: 4px 4px 14px 4px;
  &:hover {
    border: 1px solid $primary;
  }

  .content {
    text-align: center;
    border-radius: 8px;
    background-color: $white;
    padding: 16px 8px 8px;

    .image {
      height: 32px;
    }

    .title {
      font-size: 14px;
      line-height: 22px;
      margin-top: 8px;
      color: $text-primary;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    color: $text-secondary;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    padding: 0 24px;
  }
}
