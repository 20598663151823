
              @import "@/assets/css/variables.scss";
            





















































.page_panel {
  & + .page_panel {
    margin-top: 20px;
  }

  .page_panel_tab {
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      padding: 0 24px;
      line-height: 32px;
      text-align: center;
      background-color: #ededf2;
      border-radius: 8px 8px 0 0;
      color: $text-primary;
      font-size: 14px;
      width: fit-content;
      cursor: pointer;
      white-space: nowrap;

      &.is-active {
        font-weight: 700;
        background-color: $white;
        color: $primary;
      }
      & + .item {
        @include rtl-sass-prop(margin-left, margin-right, 8px);
      }
    }
  }
  .container {
    background-color: $white;
    border-radius: 8px;
    padding: 24px;
  }
}
