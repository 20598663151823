
              @import "@/assets/css/variables.scss";
            































































// @import '@/assets/css/components/register/confirmID.scss';
.el-form {
  .upload_box {
    max-width: 800px;
  }

  .title {
    font-size: 14px;
    color: $text-primary;
    line-height: 22px;
    margin-bottom: 8px;

    .upload_warn {
      font-size: 12px;
      color: $danger;
      line-height: 18px;
      @include rtl-sass-prop(margin-left, margin-right, 8px);

      &.upload_check {
        color: $text-link;
      }
    }
  }

  .tip_text {
    font-size: 12px;
    color: $danger;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .svg-icon {
    font-size: 32px;
  }

  .upload_btn {
    font-size: 12px;
    color: #7A8699;
    line-height: 18px;
    margin-top: 4px;
  }

  .tip {
    font-size: 12px;
    line-height: 18px;
    color: $text-secondary;
    margin: -16px 0 24px;

    a {
      color: $text-link;
      @include rtl-sass-prop(margin-left, margin-right, 4px);
    }
  }

  .line {
    margin: 16px 0 40px;
    width: 100%;
    height: 0;
    border: 1px dashed #DFDFE6;
  }
}
