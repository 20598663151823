
              @import "@/assets/css/variables.scss";
            




































































































































































// @import '@/assets/css/components/register/personalDetails.scss';
.id-form {
  max-width: 800px;
}

.confirm_id_title {
  font-size: 14px;
  line-height: 22px;
  color: $text-secondary;
  margin-bottom: 22px;
}

.select_input {
  width: 100%;
}
