
              @import "@/assets/css/variables.scss";
            
































































































































































































































.new_dialog_custom_class {
  border-radius: 16px;
  box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.2);
  overflow: hidden;

  &.no_header {
    .el-dialog__header {
      border-bottom: none;
    }
  }

  .el-dialog__header {
    padding: 16px 24px;
    font-size: 16px;
    line-height: 24px;
    color: $text-title;
    font-weight: 500;
    border-bottom: 1px solid $border-color-base;
    margin: 0;

    .header_content {
      position: relative;
      @include rtl-sass-prop(padding-left, padding-right, 12px);

      &::before {
        content: '';
        position: absolute;
        top: 4px;
        @include rtl-sass-prop(left, right, 0);
        width: 4px;
        height: 16px;
        border-radius: 4px;
        background-color: $primary;
      }
    }

    .el-dialog__close-btn {
      color: $black;
    }
  }

  .el-dialog__body {
    padding: 0 0 65px 0;
    box-sizing: border-box;
  }

  .dialog_body {
    padding: 24px 40px;
    max-height: 60vh;
    overflow: auto;
    font-size: 14px;

    @include screen-mobile {
      padding: 24px;
    }
  }

  .el-dialog__footer {
    position: absolute;
    bottom: 0;
    @include rtl-sass-prop(right, left, 0);
    padding: 16px 24px;
    width: 100%;
    border-top: 1px solid $border-color-base;
    background-color: $white;

    .footer_content {
      display: flex;
      justify-content: flex-end;
    }

    .el-button+.el-button {
      @include rtl-sass-prop(margin-left, margin-right, 24px);
    }
  }

  &.mode_simple {
    .el-dialog__body {
      padding: 0 0 72px 0;
      box-sizing: border-box;

      @include screen-mobile {
        padding: 0 0 56px 0;
      }
    }

    .dialog_body {
      padding: 40px 40px 20px 40px;
      max-height: none;

      @include screen-mobile {
        padding: 24px;
      }
    }

    .el-dialog__footer {
      border-top: none;
      padding: 20px 24px 40px;
      font-size: 12px;

      @include screen-mobile {
        padding: 0 24px 24px;
      }

      .footer_content {
        display: flex;
        justify-content: center;

        .el-button+.el-button {
          @include rtl-sass-prop(margin-left, margin-right, 16px);
        }
      }
    }
  }
}
