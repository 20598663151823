.link-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc((100% - (20px * 2)) / 3));
  column-gap: 16px;
  row-gap: 16px;

  li {
    border-style: solid;
    border-width: $border-width;
    border-color: $border-color;
    border-radius: 6px;
    background-color: rgb(245, 245, 249);
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    position: relative;
    padding: 20px 0 0;

    .top.title {
      height: 25px;
      background-color: $bottle-blue;
      font-size: 15px;
      color: $white;
      padding: 0 20px;
      text-transform: uppercase;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      display: -webkit-flex; /* Safari */
      align-items: center; /* Safari */
    }

    .titleSmall {
      font-size: 14px !important;
    }

    .info {
      display: grid;
      grid-template-columns: 40% 60%;
      padding: 20px;

      .right {
        @include rtl-sass-value(text-align, right, left);
        font-size: 25px;
        color: $blue;
        line-height: 1.079;

        .span {
          word-break: break-word;
        }

        .more {
          margin-top: 20px;

          span {
            border: 1px solid $blue;
            border-radius: 6px;
            font-size: 15px;
            line-height: 1.5;
            padding: 8px 10px;
            @include rtl-sass-value(margin, 0 50px 0 0, 0 0 0 50px);
          }

          > div {
            width: 21px;
            height: 21px;
            background-image: url('~@/assets/images/channel/more.png');
            @include rtl-sass-value(float, right, left);
            @include rtl-sass-prop-sd(-webkit-transform, rotate(180deg));
            @include rtl-sass-prop-sd(-moz-transform, rotate(180deg));
            @include rtl-sass-prop-sd(-ms-transform, rotate(180deg));
            @include rtl-sass-prop-sd(-o-transform, rotate(180deg));
            @include rtl-sass-prop-sd(transform, rotate(180deg));
          }
        }
      }
    }

    &:hover {
      background-color: $blue;
      box-shadow: 2.007px 2.229px 7.56px 1.44px $gray-blue;

      .top.title {
        background-color: $white;
        color: $blue;
      }

      .info {
        .logo {
          &.circle {
            background-position: -110px 0;
          }
          &.rectangle {
            background-position: 0 -45px;
          }
          &.diamond {
            background-position: -139px 0;
          }
          &.cc {
            background-position: 0 -87px;
          }
          &.visa {
            background-position: 0 -36px;
          }
        }

        .right {
          color: $white;

          .more {
            span {
              border: 1px solid $white;
            }
            > div {
              background-image: url('~@/assets/images/channel/more_active.png');
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .link-box li .info .right {
    font-size: 20px;

    .more span {
      margin: 0 20px 0 0;
    }
  }
}

@media (max-width: 1024px) {
  .link-box {
    margin-top: 0;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fill, calc((100% - 20px) / 2));

    li {
      .top.title {
        font-size: 12px;
        line-height: 1;
      }

      .titleSmall {
        font-size: 11px !important;
      }

      .info .right {
        font-size: 16px;

        .more {
          margin-top: 80px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .link-box {
    grid-template-columns: repeat(auto-fill, calc(100% / 1));
    li .info .right .more {
      margin-top: 70px;
    }
  }
}

@media (max-width: 550px) {
  .link-box li .info .right {
    font-size: 14px;
    .more {
      margin-top: 50px;
    }
  }
}

@media (max-width: 400px) {
  .link-box li .info {
    padding: 10px;
  }
}
