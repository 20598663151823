
              @import "@/assets/css/variables.scss";
            


























































































































































































// @import '@/assets/css/components/register/personalDetails.scss';
.el-form {
  max-width: 800px;
}

.required-label {
  font-size: 14px;
  line-height: 22px;
  color: $text-secondary;
  padding-bottom: 8px;

  span {
    color: $danger;
  }
}
