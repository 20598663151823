
              @import "@/assets/css/variables.scss";
            





























































// @import '@/assets/css/components/register/moreAboutYou.scss';
.tip {
  font-size: 12px;
  line-height: 18px;
  color: $text-secondary;
  margin: -16px 0 24px;
}

.label {
  font-size: 14px;
  line-height: 22px;
  color: $text-secondary;
}

.question {

  /deep/ .el-radio-group {
    .el-radio-button {
      .el-radio-button__inner {
        font-size: 14px;
        color: $text-primary;
        background-color: $background-color-base;
        height: 32px;
        border: none;
        border-radius: 16px;
        margin-top: 8px;
      }

      &:not(:last-child) {
        @include rtl-sass-prop(margin-right, margin-left, 8px);
      }

      &.is-active {
        .el-radio-button__inner {
          color: $white;
          background-color: $primary;
        }
      }
    }
  }
}

.line {
  margin: 40px 0;
  width: 100%;
  height: 0;
  border: 1px dashed #DFDFE6;
}

.el-row {
  .el-col:nth-last-child(1) {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
